@import '~theme/viewport.module.css';
@import '~theme/typography.module.css';

.HqListing-heading {
  align-items: center;
  display: flex;
  gap: var(--space-sm);
}

.HqListing-buildingNameHeading {
  font: var(--font-style-title-3);

  @media (--viewport-lg) {
    font: var(--font-style-title-1);
  }
}

.HqListing-imageCarousel {
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;

  @media (--viewport-lg) {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--grid-maxWidth);
    padding-bottom: var(--space-md);
    padding-left: var(--space-md);
    padding-right: var(--space-md);
  }
}

.HqListing-section {
  scroll-margin: var(--space-xl);
}

.HqListing-viewingRequestBanner {
  bottom: 0;
  position: sticky;
}
