.HoppyCopy {
  background-color: var(--color-brandprimary);
  border-radius: var(--space-md);
  color: var(--color-white);
  cursor: pointer;
  padding: var(--space-xs) var(--space-sm);
  text-align: center;
  text-decoration: none;
  transition-duration: var(--animation-short);
  transition-property: background-color, border-color, box-shadow, color;
  transition-timing-function: linear;
  width: fit-content;

  &:focus,
  &.sb__focus,
  &:hover,
  &.sb__hover {
    background-color: var(--color-brandprimary-hover);
    border-color: var(--color-brandprimary-hover);
    box-shadow: 0 20px 40px 0 rgb(var(--color-brandprimary-rgb) / 40%);
    color: var(--color-white);
    text-decoration: none;
    transition-duration: var(--animation-short);
    transition-property: background-color, border-color, box-shadow, color;
    transition-timing-function: linear;
  }

  &:active,
  &.sb__active {
    background-color: var(--color-brandprimary-active);
    border-color: var(--color-brandprimary-active);
    box-shadow: 0 0 0 0 rgb(var(--color-brandprimary-rgb) / 40%);
    color: var(--color-white);
    text-decoration: none;
    transition-duration: var(--animation-short);
    transition-property: background-color, border-color, box-shadow, color;
    transition-timing-function: linear;
  }
}
