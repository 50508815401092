@import '~theme/viewport.module.css';

.AdditionalDetails {
  display: flex;
  flex-direction: column;
}

.AdditionalDetails-heading {
  display: flex;
  justify-content: space-between;
}

.AdditionalDetails-advisor {
  align-items: center;
  display: flex;
  gap: var(--space-sm);
  padding: var(--space-md) 0;

  &.is-genericAdvisor {
    flex-direction: column;
  }
}
