@import '~theme/viewport.module.css';

.BookingSummary-headingImageWrapper {
  align-items: flex-start;
  border-bottom: 1px solid var(--color-neutral-100);
  display: flex;
  flex-direction: row-reverse;
  gap: 0 var(--space-md);
  justify-content: flex-end;
  margin-bottom: var(--space-md);
  padding-bottom: var(--space-md);

  @media (--viewport-md) {
    border-bottom: 0;
    flex-direction: column;
    padding-bottom: 0;
  }
}

.BookingSummary-imageWrapper {
  @media (--viewport-md) {
    margin-top: var(--space-md);
    width: 100%;
  }
}

.BookingSummary-imageSmall,
.BookingSummary-imageLarge {
  border-radius: var(--space-md);
  display: block;
}

.BookingSummary-imageSmall {
  @media (--viewport-md) {
    display: none;
  }
}

.BookingSummary-imageLarge {
  display: none;

  @media (--viewport-md) {
    display: block;
  }
}

.BookingSummary-dataList {
  font: var(--font-style-content-2);
  margin-bottom: 0;

  & > div {
    align-items: start;
    display: flex;
    justify-content: space-between;

    @media (--viewport-md) {
      border-bottom: 1px solid var(--color-neutral-200);
      padding: var(--space-md) 0;
    }

    &:last-of-type {
      border-bottom: none;
      padding: var(--space-md) 0 0;
    }

    /* Only show the first data point on mobile */
    &:not(:first-child) {
      display: none;

      @media (--viewport-md) {
        display: flex;
      }
    }
  }
}

.BookingSummary-description {
  text-align: right;
}

.DropdownSelector-askAdminLabel {
  align-items: center;
  color: var(--color-neutral-700);
  display: flex;
  justify-content: center;
}
