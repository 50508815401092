@import '~theme/viewport.module.css';

.Form-advisorDetails {
  display: flex;
  flex-direction: column;
  gap: var(--space-sm);
  text-align: center;

  @media (--viewport-md) {
    flex-direction: row;
  }
}
